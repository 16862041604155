<template>
  <div class="h-full boxSize overflow-auto">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row">
      <div class="form-col">
        <div class="form-label blue-title">prospect's information</div>
        <p class="text-sm" for="name">
          {{ appoinmentDetail.name}}
        </p>
        <p class="text-sm" for="email">
          {{ appoinmentDetail.email }}
        </p>
        <p class="text-sm" for="phone_number">
          {{ appoinmentDetail.phone_number }}
        </p>  
      </div>
      <div class="form-col">
        <div class="form-label blue-title">appointment date & time</div>
        <p class="text-sm" for="start_time">
          {{ appoinmentDetail.start_time }}<br/> 
        </p>
        <p class="text-sm" for="end_time">
          {{ appoinmentDetail.end_time}}
        </p>
      </div>
      <div class="form-col">
        <div class="form-label blue-title">leasing agent</div>              
      </div>
    </div>
    <div class="form-row">
      <div class="form-col">
        <div class="form-label">desired number of bedrooms</div>
        <ul class="py-3 list-disc" v-if="appoinmentDetail.desired_number_of_bedrooms !== 'no data available'">
          <li
            v-for="roomNumber in appoinmentDetail.desired_number_of_bedrooms"
            :key="roomNumber"  
            class="pl-0 mt-0 mr-0 mb-2 ml-5"              
          >
            <p class="text-sm" for="roomNumber">
            {{ roomNumber }}
            </p>
          </li>
        </ul>
        <p class="text-sm" for="roomNumber" v-else>
          no data available
        </p>
      </div>
      <div class="form-col">
        <div class="form-label">activity source</div>
        <p class="text-sm" for="activity_source">{{ appoinmentDetail.activity_source_name }}</p>
        <div class="form-label mt-6">max budget</div>
        <p class="text-sm" for="max_budget">
          {{ isNaN(appoinmentDetail.max_budget) ? appoinmentDetail.max_budget : `$${appoinmentDetail.max_budget}`}}
        </p>   
      </div>
      <div class="form-col">
        <div class="form-label">move-in date</div>
        <p class="text-sm" for="move_in_date">{{ appoinmentDetail.move_in_date }}</p>  
      </div>
    </div>
    <div class="form-row">
      <div class="form-col">
        <div class="form-label">notes</div> 
        <p class="text-sm" for="notes">{{ appoinmentDetail.notes }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import AuthMixin from "@/components/auth/AuthMixin";
import ModalNavigation from "@/mixins/ModalNavigation";
import NotifyMixin from '@/mixins/NotifyMixin'
import Loader from '@/components/ui/Loader'
import moment from 'moment';

export default {
  mixins: [AuthMixin, ModalNavigation, NotifyMixin],
  components: {
    Loader
  },
  data() {
    return {
      data: null,
      loading: false,
    };
  },
  methods: {
    fetchAppointmentDetail() {
      this.loading = true;
      this.$calendarDataProvider
        .getList('getAppointment', { appointment_id: this.$route.params.appointment_id })
        .then((res) => {
          this.data = res;
        })
        .catch((error) => this.notifyError(error.message))
        .finally(() => (this.loading = false))
    },
    validateData(value) {
      return value ? value : 'no data available'
    },
  },
  created() {
    this.fetchAppointmentDetail();
  },
  computed: {
    appoinmentDetail() {
      if (!this.data) {
        return {};
      }

      return {
        name: this.validateData(this.data.guest.first_name && this.data.guest.last_name ? `${this.data.guest.first_name} ${this.data.guest.last_name}` : null),
        email: this.validateData(this.data.guest.email),
        phone_number: this.validateData(this.data.guest.phone_number),
        move_in_date: this.validateData(this.data.move_in_date ? moment(this.data.move_in_date).format('DD/MM/YYYY') : null),
        start_time: this.validateData(this.data.start_time ? moment(this.data.start_time).format("dddd, MMMM Do, YYYY") : null),
        end_time: this.validateData(this.data.start_time && this.data.end_time ? `${moment(this.data.start_time).format('hh:mm')} - ${moment(this.data.end_time).format('hh:mm a')}` : null),
        desired_number_of_bedrooms: this.validateData(this.data.desired_number_of_bedrooms),
        activity_source_name: this.validateData(this.data.activity_source_name),
        max_budget: this.validateData(this.data.max_budget),
        notes: this.validateData(this.data.notes),
      };
    },
  },
};
</script>

<style scoped>
.blue-title {
  @apply text-blue-500 !important;
}
</style>
